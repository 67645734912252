<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-3 col-lg-3 mb-6">
        <div class="card" style="background-color: rgba(0, 0, 0, 0.03);">
          <div class="card-body">

                  <div class="row mt-3">
                    <div class="col-4">
                      <input type="text" @keypress="onlyNumber($event)" class="form-control text-center" placeholder="ID" v-model="searchID.id" />
                    </div>
                    <div class="col-6 d-grid">
                      <button class="btn btn-secondary text-white" @click="goTale(searchID)">GİT
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.43783 0.91668L12.9378 6.41668C13.0212 6.50001 13.0803 6.59029 13.1153 6.68751C13.1498 6.78474 13.167 6.8889 13.167 7.00001C13.167 7.11112 13.1498 7.21529 13.1153 7.31251C13.0803 7.40974 13.0212 7.50001 12.9378 7.58335L7.43783 13.0833C7.28505 13.2361 7.09421 13.3161 6.86533 13.3233C6.63588 13.33 6.43783 13.25 6.27116 13.0833C6.10449 12.9306 6.01755 12.7397 6.01033 12.5108C6.00366 12.2814 6.08366 12.0833 6.25033 11.9167L10.3337 7.83335L1.02116 7.83335C0.785048 7.83335 0.586992 7.75362 0.426992 7.59418C0.267548 7.43418 0.187825 7.23612 0.187825 7.00001C0.187825 6.7639 0.267548 6.56612 0.426992 6.40668C0.586992 6.24668 0.785048 6.16668 1.02116 6.16668L10.3337 6.16668L6.25033 2.08335C6.09755 1.93057 6.01755 1.73612 6.01033 1.50001C6.00366 1.2639 6.08366 1.06946 6.25033 0.91668C6.4031 0.750014 6.59755 0.666679 6.83366 0.666679C7.06977 0.666679 7.27116 0.750014 7.43783 0.91668Z" fill="white"/>
                        </svg>
                      </button>
                    </div>
                    <hr class="mt-2" />
                    <div class="col-12">
                      DURUM
                    </div>
                    <div class="col-12">
                      <select class="form-select" aria-label="Select status" aria-placeholder="Select Status" v-model="searchQuery.status">
                        <option value="">Tümü</option>
                        <option value="request">Masal İstekleri</option>
                        <option value="complete">Yayınlanmış</option>
                      </select>
                    </div>
                    <hr class="mt-2" />
                    <div class="col-12">
                      Başlık
                    </div>
                    <div class="col-12">
                      <input type="text" class="form-control" placeholder="Başlık" v-model="searchQuery.title" />
                    </div>
                    <hr class="mt-2" />
                    <div class="col-12">
                      Kategori
                    </div>
                    <div class="col-12">
                      <select class="form-select" aria-label="Kategori Seç" aria-placeholder="Kategori Seç" v-model="searchQuery.category">
                        <option value="" selected>Tümü</option>

                          <option v-for="(a,index) in catAll" :key="index" v-bind:value="a">{{a.name}}</option>
                      </select>
                    </div>
                    <hr class="mt-2" />
                    <div class="col-12">
                      Yaş
                    </div>
                    <div class="col-12">
                      <select class="form-select" aria-label="Select status" aria-placeholder="Select Status" v-model="searchQuery.age">
                        <option value="" selected>Tümü</option>
                          <option v-for="(a,index) in ages" :key="index" v-bind:value="a">{{a.min_age}}+</option>
                      </select>
                    </div>
                    <div class="col-12 mt-3 mp-2 d-grid">
                    <button
                      class="btn btn-primary text-white"
                      type="submit"
                      @click="loadSearch()"
                    > ARA
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.43783 0.91668L12.9378 6.41668C13.0212 6.50001 13.0803 6.59029 13.1153 6.68751C13.1498 6.78474 13.167 6.8889 13.167 7.00001C13.167 7.11112 13.1498 7.21529 13.1153 7.31251C13.0803 7.40974 13.0212 7.50001 12.9378 7.58335L7.43783 13.0833C7.28505 13.2361 7.09421 13.3161 6.86533 13.3233C6.63588 13.33 6.43783 13.25 6.27116 13.0833C6.10449 12.9306 6.01755 12.7397 6.01033 12.5108C6.00366 12.2814 6.08366 12.0833 6.25033 11.9167L10.3337 7.83335L1.02116 7.83335C0.785048 7.83335 0.586992 7.75362 0.426992 7.59418C0.267548 7.43418 0.187825 7.23612 0.187825 7.00001C0.187825 6.7639 0.267548 6.56612 0.426992 6.40668C0.586992 6.24668 0.785048 6.16668 1.02116 6.16668L10.3337 6.16668L6.25033 2.08335C6.09755 1.93057 6.01755 1.73612 6.01033 1.50001C6.00366 1.2639 6.08366 1.06946 6.25033 0.91668C6.4031 0.750014 6.59755 0.666679 6.83366 0.666679C7.06977 0.666679 7.27116 0.750014 7.43783 0.91668Z" fill="white"/>
                        </svg>

                    </button>
                    </div>


                  </div>


          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-9 col-lg-9 mb-6 ">
        <div class="card border-0">
          <div class="card-body border-0">
            <span v-show="!data.length && !loading">
              Sonuç bulunamadı.
            </span>
              <span v-show="!loading" v-for="row in data" :key="row.id">
                  <div class="row mb-3" @click="goTale(row)" type="button" v-bind:style="[row.metadata ? {'cursor': 'not-allowed'}:{}]">
                      <div class="border rounded pt-2 px-0 text-center col-2" style="width: 60px; line-height: 30px;font-weight: 600;font-size: 15px;color: #222F3E;background-color:#fff;">{{row.id}}</div>
                      <div class=" col-10">
                        <span style="font-weight: 500;font-size: 15px;line-height: 20px;color: #222F3E;">{{row.title}}</span><br />
                      <span v-if="!row.metadata" style="font-weight: 500;font-size: 13px;color: #888888;">{{row.request_type}}</span>
                      <div v-if="row.metadata" style="font-weight: 500;font-size: 13px;color: #888888;"><div class="blink mt-1 me-2"> </div> {{row.metadata.first_name}} {{row.metadata.last_name}} düzenliyor <i class="bi bi-pencil"></i> </div>
                      </div>
                  </div>
              </span>

              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get,getAges,getCategory,getSearch } from "../service/tales";

export default {
  name: "Home",
  data() {
    return {
      data: {},
      ages: {},
      catAll: {},
      editData: {},
      loading: true,
      isModalVisible: false,
      metadata: {},
      interval: null,
      searchID: {id:''},
      searchQuery: {
        age: null,
        category: null,
        title: null,
        status: null
      }
    };
  },
    methods:{
    getAges: function(){
    getAges().then((res) => {
        this.ages = res.data.data;
    })
    },
    getCategory(){
      getCategory().then((res) => {
        this.catAll = res.data.data;
      });
    },
    goTale: function(tale){
      if(!tale.metadata) {
        let self = this;
        if(tale.id !== '') {
          get(tale.id).then((res) => {
              this.searchID.id = '';
              window.location.replace("/tale/" + res.data.data.id);
            }).catch(function (error) {
                if (error.response) {
                  self.searchID.id = '';
                  self.$notify({
                    group: 'foo',
                    title: 'Hata',
                    text: "Lütfen masal IDsi giriniz."
                  });
                }
        });
      } else {
          self.$notify({
            group: 'foo',
            title: 'Hata',
            text: "Lütfen masal IDsi giriniz."
          });
      }
      }
    },
    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault();
      }
    },
    loadAll: function() {

     // search.category_id = 1;
     //this.searchQuery.status !== null ? search.status = this.searchQuery.status : '';
     this.searchQuery = this.$store.state.searchQuery;

     this.loadSearch();
    },
    loadSearch(){
              this.loading = true;

           let search =  {
                  age_id: (this.searchQuery.age.id ? this.searchQuery.age.id : ''),
                  category_id: (this.searchQuery.category?.id ? this.searchQuery.category?.id : ''),
                  title: (this.searchQuery?.title ? this.searchQuery?.title : ''),
                  status: (this.searchQuery?.status ? this.searchQuery?.status : ''),
          }

          this.searchQuery?.age?.id > 0 ? '' : delete search.age_id ;
          this.searchQuery.category.id ? '' : delete search.category_id;
          this.searchQuery.title == null ? delete search.title : '';
          this.searchQuery.title == '' ? delete search.title : '';
          this.searchQuery.status ? '' : delete search.status;


      getSearch(search).then((res) => {
        this.data = res.data.data;
        this.loading = false;
      });
    }
  },
    mounted() {
    this.getCategory();
    this.getAges();
    this.loadAll();
  }
};
</script>
