<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body m-3">
                YENİ MASAL
                <form @submit.prevent="handleSave" class="mt-3">
                  Başlık
                  <div class="form-floating mb-3">
                    <input required
                      type="text"
                      class="form-control"
                      id="nameInput"
                      v-model="name"
                    />
                    <label for="nameInput">Başlık</label>
                  </div>
                  İlk Prompt
                  <div class="form-floating mb-3">

                    <textarea required
                      type="text"
                      class="form-control"
                      id="promtInput"
                      v-model="summary"
                    />
                    <label for="workersInput">İlk Prompt</label>
                  </div>

                </form>
                <div class="row  pt-3">
                  <div class="col-4">
                    <a
                      class=""
                      type="button"
                      @click="$emit('close')"
                    >Kapat
                    </a>
                  </div>
                  <div class="col-3 text-end">
                    <button
                      class="btn btn-primary btn-login btn-sm text-white"
                      type="submit" @click="handleSave(0)"
                      :disabled="submitted"
                    >
                      <span v-if="!submitted">KAYDET</span>
                      <span
                        v-if="submitted"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                  <div class="col-5 text-end">
                    <button
                      class="btn btn-primary btn-login btn-sm text-white"
                      type="submit" @click="handleSave(1)"
                      :disabled="submitted"
                    >
                      <span v-if="!submitted">KAYDET VE DÜZENLE</span>
                      <span
                        v-if="submitted"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                </div>
                <div class="mt-3 text-danger">{{ message }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { create } from "../service/tales";

export default {
  name: "FacilityModal",
  props: {
    postData: Object,
  },
  data: () => ({
    showModal: false,
    submitted: false,
    message: "",
    name: "",
    summary: ""
  }),
  methods: {
    handleSave(d) {

      if(this.summary.length == 0 && this.name.length == 0) {
              this.$notify({
              group: 'foo',
              title: 'Uyarı',
              text: 'Lütfen alanları doldurunuz.'
            })
            return;
      } else if(this.name.length < 4) {
              this.$notify({
              group: 'foo',
              title: 'Uyarı',
              text: 'Lütfen en az 4 karakterli başlık giriniz'
            })
            return;
      } else if(this.summary.length < 10) {
              this.$notify({
              group: 'foo',
              title: 'Uyarı',
              text: 'Lütfen en az 10 karakterli prompt giriniz'
            })
            return;
      }



      let self = this;
      this.message = "";
      this.submitted = true;
      create({"title": this.name, "first_prompt":this.summary}).then((res) => {
        if (res.data.data.id) {
          this.submitted = false;
          this.$store.state.facilityReload = true;
          this.name = "";
          this.summary = "";
          if(d == 1) {
            this.$router.push("/tale/" + res.data.data.id)
          } else {
                      this.$emit('close')
          }
        } else {
          this.message = this.$t("error." + res.data.message);
          this.submitted = true;
        }
        }).catch(function (error) {
          if (error.response) {
          self.submitted = false;
            self.$notify({
              group: 'foo',
              title: 'Error',
              text: error.response.data.msg ? error.response.data.msg : error.response.data.message
            })
          }
          })
    },
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
