import axios from "axios";
import authHeader from "../utils/authHeader";

const API_URL = process.env.VUE_APP_API_URL;

export const getAll = () => {
  return axios.get(API_URL + "tales/", { headers: authHeader() });
};

export const get = (id) => {
  return axios.get(API_URL + "tales/" + id, { headers: authHeader() });
};

export const create = (d) => {
  return axios.post(API_URL + "tales/", d, { headers: authHeader() });
};

export const getCategory = () => {
  return axios.get(API_URL + "category/", { headers: authHeader() });
};

export const createCategory = (d) => {
  return axios.post(API_URL + "category/", d, { headers: authHeader() });
};

export const updateCategory = (id,d) => {
  return axios.patch(API_URL + "category/" + id, d, { headers: authHeader() });
};
export const deleteCategory = (id) => {
  return axios.delete(API_URL + "category/" + id, { headers: authHeader() });
};

export const update = (d) => {
  return axios.put(API_URL + "tales/", d, { headers: authHeader() });
};

export const patch = (id,d) => {
  return axios.patch(API_URL + "tales/" + id, d, { headers: authHeader() });
};

export const remove = (d) => {
  return axios.delete(API_URL + "tales/", { data: d, headers: authHeader() });
};

export const getPrompt = (id) => {
  return axios.get(API_URL + "tales/" + id + "/prompt", { headers: authHeader() });
};
export const updatePrompt = (id,d) => {
  return axios.put(API_URL + "prompt/" + id, d, { headers: authHeader() });
};

export const createPrompt = (id,type,d) => {
  return axios.post(API_URL + "tales/" + id + "/prompt?prompt_type=" + type, {...d, prompt_type:type}, { headers: authHeader() });
};

export const deletePrompt = (id) => {
  return axios.delete(API_URL + "prompt/" + id, { headers: authHeader() });
};

export const getImages = (id) => {
  return axios.get(API_URL + "tales/" + id + "/images", { headers: authHeader() });
};

export const getPages = (id) => {
  return axios.get(API_URL + "tales/" + id + "/page", { headers: authHeader() });
};

export const createPage = (id,d) => {
  return axios.post(API_URL + "tales/" + id + "/page", d, { headers: authHeader() });
};

export const updatePage = (id,d) => {
  return axios.patch(API_URL + "pages/" + id, d, { headers: authHeader() });
};

export const deletePage = (id) => {
  return axios.delete(API_URL + "pages/" + id, { headers: authHeader() });
};

export const deleteImage = (id) => {
  return axios.delete(API_URL + "image/" + id, { headers: authHeader() });
};
export const createImage = (id,d) => {

  var formdata = new FormData();
  formdata.append('photo',d);

  var requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: formdata,
    redirect: 'follow'
  };

 return fetch(API_URL + "tales/" + id + "/images", requestOptions)

};


export const getAges = () => {
  return axios.get(API_URL + "age", { headers: authHeader() });
};

export const createAges = (d) => {
  return axios.post(API_URL + "age", d, { headers: authHeader() });
};

export const updateAges = (id,d) => {
  return axios.patch(API_URL + "age/" + id, d, { headers: authHeader() });
};

export const deleteAges = (id) => {
  return axios.delete(API_URL + "age/" + id, { headers: authHeader() });
};

export const getLanguages = () => {
  return axios.get(API_URL + "languages", { headers: authHeader() });
};

export const getConnect = (id) => {
  return axios.get(API_URL + "tales/" + id + "/connect", { headers: authHeader() });
};

export const getSearch = (query) => {
  return axios.get(API_URL + "tales?search&" + Object
  .keys(query)
  .map(value => `${value}=${encodeURIComponent(query[value])}`)
  .join('&'), { headers: authHeader() });
};

