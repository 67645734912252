import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user : '',
    darkmode: false,
    facilityReload: false,
    facilityUsageReload: false,
    searchQuery: {
      age: null,
      category: null,
      title: null,
      status: null
    }
  },
  mutations: {
    searchUpdate(state,payload) {
      state.searchQuery = payload;
    },
  },
  actions: {
    tokenControl() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.access_token) {
        this.state.user = user
      }
    }
  },
  modules: {
  }
})
