<template>
  <div class="container">
    <div class="row mt-5">
    </div>
      <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card border-1 rounded-3 mt-5 mb-3">
          <div class="card-body p-3 p-sm-4">
            <h5 class="card-title text-left mb-3 fw-light fs-5">Şifre Sıfırlama</h5>
            <form @submit.prevent="handleLogin">
              <div class="form-floating mb-3" >
                <input
                  type="password"
                  class="form-control form-control-sm"
                  v-model="password"
                  id="emailInput"
                  placeholder="Şifre"
                />
                <label for="emailInput">Şifre</label>
              </div>
              <div class="form-floating mb-3 ">
                <input
                  type="password"
                  class="form-control"
                  v-model="passwordConfirm"
                  placeholder="Şifre Tekrar"
                  id="passInput"
                />
                <label for="passInput">Şifre Tekrar</label>
              </div>
              <div class="row">
              <div class="col-6">


              </div>
              <div class="col-6 justify-content-md-end float-end">
                <button
                  class="btn btn-primary btn-login float-end text-white"
                  type="submit"
                  :disabled="submitted"
                >
                  <span v-if="!submitted">ŞİFRE SIFIRLA</span>
                  <span v-if="submitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
                <div class="mt-3 text-danger">{{message}}</div>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { passwordResettoUpdate } from "../service/user";

export default {
  name: "passwordReset",
  data() {
    return {
      password: '',
      passwordConfirm: '',
      submitted: false,
      successful: false,
      message: "",
      rememberMe: true,
      passReset: false,
    };
  },
  methods: {
    handleLogin() {

      if(this.passwordConfirm == '' || this.password == '') {
          this.$notify({
              group: 'foo',
              title: 'Uyarı',
              text: 'Şifre alanlarını doldurun.'
            })
            return;
      }

      if(this.passwordConfirm != this.password ) {
          this.$notify({
              group: 'foo',
              title: 'Uyarı',
              text: 'Şifreler birbiri ile uyuşmuyor.'
            })
            return;
      }

      if(this.passwordConfirm.length < 8 ) {
          this.$notify({
              group: 'foo',
              title: 'Uyarı',
              text: 'Şifre en az 8 en fazla 20 karakterden oluşan bir string olmalıdır, Şifre en az bir rakam veya alfanümerik olmayan bir karakter içermelidir, Şifre en az bir büyük harf ve en az bir küçük harf içermelidir.'
            })
            return;
      }

      let self = this;
      this.message = "";
      this.submitted = true;
        passwordResettoUpdate({password:this.password,password_confirm:this.passwordConfirm,token:this.$route.query.token}).then((res)=>{
          if(res.status == 204) {
            self.$notify({
              group: 'foo',
              title: 'Başarılı',
              text: res.data.data
            })
            this.passReset = false;
            this.$router.push("/login")

          }
          this.submitted = false;


        }).catch(function (error) {
          if (error.response) {
          self.submitted = false;
            self.$notify({
              group: 'foo',
              title: 'Hata',
              text: error.response.data.msg ? error.response.data.msg : error.response.data.message
            })
          }
          })
      }

    }
  }
</script>
