<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body m-3">
                DÜZENLE
                <form @submit.prevent="handleSave" class="mt-3">
                  Masal
                  <div class="form-floating mb-3">

                    <textarea required
                      type="text"
                      class="form-control pt-1"
                      id="promtInput"
                      rows="10"
                      height="250"
                      v-model="postData.story"
                    />
                  </div>

                </form>
                <div class="row  pt-3">
                  <div class="col-6">
                    <a
                      class=""
                      type="button"
                      @click="$emit('close')"
                    >Kapat
                    </a>
                  </div>
                  <div class="col-6 text-end">
                    <button
                      class="btn btn-primary btn-login btn-sm text-white"
                      type="submit" @click="handleSave()"
                      :disabled="submitted"
                    >
                      <span v-if="!submitted">KAYDET</span>
                      <span
                        v-if="submitted"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                </div>
                <div class="mt-3 text-danger">{{ message }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { patch } from "../service/tales";

export default {
  name: "FacilityModal",
  props: {
    postData: Object,
    postID: String
  },
  data: () => ({
    showModal: false,
    submitted: false,
    message: "",
  }),
  methods: {
    handleSave() {
    if(this.postData.story.length == 0) {
              this.$notify({
              group: 'foo',
              title: 'Uyarı',
              text: 'Lütfen hikaye alanını doldurunuz.'
            })
            return;
      }
      let self = this;
      this.message = "";
      this.submitted = true;

      patch(this.postID, {"story":this.postData.story}).then((res) => {
        if (res.data.data.id) {
          this.submitted = false;
          this.$store.state.facilityReload = true;
          this.promt = "";
          this.$emit('close')
        } else {
          this.message = this.$t("error." + res.data.message);
          this.submitted = false;
        }
        }).catch(function (error) {
          if (error.response) {
          self.submitted = false;
            self.$notify({
              group: 'foo',
              title: 'Hata',
              text: error.response.data.msg ? error.response.data.msg : error.response.data.message
            })
          }
          })
    },
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
