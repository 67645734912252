<template>
  <div class="container">
    <div class="row mt-5">
    </div>
      <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card border-1 rounded-3 mt-5 mb-3">
          <div class="card-body p-3 p-sm-4">
            <h5 class="card-title text-left mb-3 fw-light fs-5">{{passReset ? 'Şifre Sıfırlama' : 'Hesabınıza giriş yapın'}}</h5>
            <form @submit.prevent="handleLogin">
              <div class="form-floating mb-3" >
                <input
                  type="email"
                  class="form-control form-control-sm"
                  v-model="user.email"
                  id="emailInput"
                  placeholder="Email"
                />
                <label for="emailInput">{{ $t('email')}}</label>
              </div>
              <div class="form-floating mb-3 " v-show="!passReset">
                <input
                  type="password"
                  class="form-control"
                  v-model="user.password"
                  placeholder="Şifre"
                  id="passInput"
                />
                <label for="passInput">Şifre</label>
              </div>
              <div class="row">
              <div class="col-6">
                  <label class="form-check-label" for="checkInput" @click="passwordResetStatus()">
                    {{passReset ? '>> Giriş' : 'Şifremi Unuttum'}}
                  </label>
              </div>
              <div class="col-6 justify-content-md-end float-end">
                <button
                  class="btn btn-primary btn-login float-end text-white"
                  type="submit"
                  :disabled="submitted"
                >
                  <span v-if="!submitted"> {{passReset ? 'ŞİFRE SIFIRLA' : 'GİRİŞ'}}</span>
                  <span v-if="submitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
                <div class="mt-3 text-danger">{{message}}</div>
              </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import User from "../model/user";
import { login, passwordReset } from "../service/user";
import { mapState } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      user: new User("", "", ""),
      submitted: false,
      successful: false,
      message: "",
      rememberMe: true,
      passReset: false,
    };
  },
  computed: {
    ...mapState(["facilityUsageReload"]),
  },
  methods: {
    handleLogin() {

      if(this.user.email == '' || this.user.password == '' && !this.passReset) {
          this.$notify({
              group: 'foo',
              title: 'Uyarı',
              text: 'Lütfen email ve şifrenizi giriniz'
            })
            return;
      }

      if(this.user.email == '') {
          this.$notify({
              group: 'foo',
              title: 'Uyarı',
              text: 'Lütfen email giriniz'
            })
            return;
      }

      let self = this;
      this.message = "";
      this.submitted = true;
      if(this.passReset){
        passwordReset({email:this.user.email}).then((res)=>{
          if(res.data.success) {
            self.$notify({
              group: 'foo',
              title: 'Başarılı',
              text: res.data.data
            })
            this.passReset = false;
          }
          this.submitted = false;

        }).catch(function (error) {
          if (error.response) {
          self.submitted = false;
            self.$notify({
              group: 'foo',
              title: 'Hata',
              text: error.response.data.msg ? error.response.data.msg : error.response.data.message
            })
          }
          })
      } else {
      login(this.user).then(
        (res) => {
          if(res.data.access_token) {
              this.$store.state.user = res.data
              if(this.rememberMe) {
              localStorage.setItem("user", JSON.stringify(res.data))
              }
              this.$router.push("/")
          } else {
            self.submitted = false;
          }
        }).catch(function (error) {
          if (error.response) {
          self.submitted = false;
            self.$notify({
              group: 'foo',
              title: 'Hata',
              text: error.response.data.msg ? error.response.data.msg : error.response.data.message
            })
          }
          })
      }

    },
    passwordResetStatus() {
      this.passReset = !this.passReset;
    }
  },
};
</script>
