<template>
  <div id="app" v-bind:class="{ dark: this.$store.state.darkmode }">
    <Navbar v-show="navbarShow" />
    <div class="container-fluid pt-3 bo">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <notifications group="foo" position="top center" classes="notification" />
  </div>
</template>

<script>
import Navbar from "./views/Navbar.vue";
import { mapActions } from 'vuex'

export default {
  name: "App",
  components: {
    Navbar,
  },
    methods: {
    ...mapActions(['tokenControl',])
    },
  computed: {
    navbarShow() {
        return true;
    },
  },
  mounted() {
      this.tokenControl()
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap');
@import "./assets/style.scss";

:root {
  @import "../node_modules/bootstrap/scss/functions";
  @import "../node_modules/bootstrap/scss/variables";
  @import "../node_modules/bootstrap/scss/mixins";
  @import "./assets/dark.scss";
}

.bo {
  border-top: 1px solid #E9F7FE;

}
</style>
