<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body m-3">
                <form @submit.prevent="handleSave" class="mt-3">
                  <div class="form-floating mb-3">

                    <input required
                      type="text" @keypress="onlyNumber($event)"
                      class="form-control pt-1"
                      id="promtInput"
                      v-model="promt"
                    />
                  </div>

                </form>
                <div class="row  pt-3">
                  <div class="col-6">
                    <a
                      class=""
                      type="button"
                      @click="$emit('close')"
                    >Kapat
                    </a>
                  </div>
                  <div class="col-6 text-end">
                    <button
                      class="btn btn-primary btn-login btn-sm text-white"
                      type="submit" @click="handleSave()"
                      :disabled="submitted"
                    >
                      <span v-if="!submitted">{{ processType.id ? 'Kaydet' : 'Ekle'}} </span>
                      <span
                        v-if="submitted"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                </div>
                <div class="mt-3 text-danger">{{ message }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { createAges, updateAges, createCategory, updateCategory } from "../service/tales";

export default {
  name: "SettingsModal",
  props: {
    postData: String,
    promtType: Number,
    processType: Object
  },
  data: () => ({
    showModal: false,
    submitted: false,
    message: "",
    promt: ""
  }),
  methods: {
    onlyNumber ($event) {
      if(this.promtType == 2){
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
            $event.preventDefault();
        }
      }
    },
    handleSave() {
      if(this.promt.length == 0) {
      this.$notify({
        group: 'foo',
        title: 'Uyarı',
        text: "Boş kayıt yapılamaz."
      })
        return;
      }

      this.message = "";
      this.submitted = true;
if(this.promtType == 2){
      if(this.processType.age_range) {
        updateAges(this.processType.id, {"age_range":this.promt}).then((res) => {
          if (res.data.data.id) {
            this.submitted = false;
            this.$store.state.facilityReload = true;
            this.promt = "";
            this.$emit('close')
          } else {
            this.message = this.$t("error." + res.data.message);
            this.submitted = false;
          }
        });
      } else {
        createAges({"age_range":this.promt}).then((res) => {
          if (res.data.data.id) {
            this.submitted = false;
            this.$store.state.facilityReload = true;
            this.promt = "";
            this.$emit('close')
          } else {
            this.message = this.$t("error." + res.data.message);
            this.submitted = false;
          }
        });
      }
}

if(this.promtType == 1){
      if(this.processType.name) {
        updateCategory(this.processType.id, {"name":this.promt}).then((res) => {
          if (res.data.data.id) {
            this.submitted = false;
            this.$store.state.facilityReload = true;
            this.promt = "";
            this.$emit('close')
          } else {
            this.message = this.$t("error." + res.data.message);
            this.submitted = false;
          }
        });
      } else {
        createCategory({"name":this.promt}).then((res) => {
          if (res.data.data.id) {
            this.submitted = false;
            this.$store.state.facilityReload = true;
            this.promt = "";
            this.$emit('close')
          } else {
            this.message = this.$t("error." + res.data.message);
            this.submitted = false;
          }
        });
      }
}


    },
  },
  mounted() {
    this.promt = ( this.processType.name ? this.processType.name : (this.processType.age_range ? this.processType.age_range : '') )
  },
  watch: {
    'processType' (){
    this.promt = ( this.processType.name ? this.processType.name : (this.processType.age_range ? this.processType.age_range : '') )
    }

  }

};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
