import Vue from 'vue'
import App from './App.vue'
import store from './store'
import Vuex from 'vuex';
import router from './router'
import i18n from './i18n'
import HighchartsVue from "highcharts-vue";
import Notifications from 'vue-notification'

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import Multiselect from 'vue-multiselect'
Vue.use(Multiselect);
Vue.config.productionTip = false
Vue.use(Vuex);
Vue.use(HighchartsVue);
Vue.use(Notifications)


new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
