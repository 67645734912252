<template>
  <div>
    <div class="container">
      <div class="row mb-3 pb-3">
        <div class="col-6 text-start"><div style="padding:5px 15px; border-radius:8px; width: min-content; border:1px solid #ccc;float:left;" class="me-3">{{ this.$route.params.id }}</div>
        <div class="p-0" style="color:#22303F;font-weight: 400;font-size:24px"> {{ tale.title }}</div></div>
        <div class="col-6 text-end">
          <a class=" me-3" type="button" @click="archive(true)" v-if="!tale.is_archived">
            <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.5 18.5C2.95 18.5 2.47917 18.3042 2.0875 17.9125C1.69583 17.5208 1.5 17.05 1.5 16.5V3.5H0.5V1.5H5.5V0.5H11.5V1.5H16.5V3.5H15.5V16.5C15.5 17.05 15.3042 17.5208 14.9125 17.9125C14.5208 18.3042 14.05 18.5 13.5 18.5H3.5ZM13.5 3.5H3.5V16.5H13.5V3.5ZM5.5 14.5H7.5V5.5H5.5V14.5ZM9.5 14.5H11.5V5.5H9.5V14.5Z" fill="#747375"/>
            </svg>

          </a>
          <button class="btn btn-outline-light me-3" @click="close()" >
            KAPAT
          </button>
          <button class="btn btn-outline-primary me-3" :class="{disabled: tale.role == 'complete'}" @click="update()" >
            KAYDET
          </button>
          <button class="btn btn-primary text-white me-2" v-if="!tale.is_archived && tale.role == 'request'" @click="publish()">
            YAYINLA
          </button>
          <button class="btn btn-secondary text-white me-2" v-if="!tale.is_archived && tale.role == 'complete'" @click="publish()">
            YAYINDAN ÇIKAR
          </button>
          <button class="btn btn-outline-primary me-2" @click="archive(false)" v-if="tale.is_archived">
            ARŞİVDEN ÇIKAR
          </button>
        </div>
      </div>

      <hr style="border: 1px solid #E9F7FE;"/>
      <div class="row" >
        <div class="col-4">
        <div class="p-3" style="background-color:#f5f5f5;border-radius:8px; border: 1px solid #F1F1F1;height: 550px;">

          <div style="font-weight: 400;font-size: 16px;line-height: 22px;text-transform: uppercase;color: #22303F;" class="mb-3"> AYARLAR</div>

                  <div class="row mt-3 fs-6 text">

                    <div class="col-12 mt-3">
                      Başlık
                    </div>
                    <div class="col-12 mb-3">
                      <input type="text" class="form-control" placeholder="Name" v-model="tale.title" />
                    </div>

                    <div class="col-12 fs-6 text mt-3">
                      Dil
                    </div>
                    <div class="col-12 mb-3">
                      <select class="form-select" aria-label="Select status" aria-placeholder="Select Status" v-model="selectedLang">
                        <option selected>Dil seç</option>
                          <option v-for="(l,index) in languages" :key="index" v-bind:value="l">{{l.name}}</option>
                      </select>
                    </div>

                    <div class="col-12 mt-3">
                      Kategori
                    </div>

                    <div class="col-12 mb-3">
                    <multiselect
                    v-model="selectCat"
                    selectLabel="Seç"
                    selectedLabel="Seçildi"
                    deselectLabel="Kaldır"
                    :options="catAll"
                    :multiple="true"
                    placeholder="Kategori Seç"
                    label="name"
                    track-by="name">
                      <template slot="selection" ><span class="multiselect__single" v-if="catAll.length">{{ selectCat.length }} kategori seçildi</span></template>
                    </multiselect>

                    </div>

                    <div class="col-12 mt-3">
                      Yaş
                    </div>
                    <div class="col-12 mb-3">
                      <select class="form-select" aria-label="Select status" aria-placeholder="Select Status" v-model="selectedAge">
                        <option selected>+ Yaş seç</option>
                          <option v-for="(a,index) in ages" :key="index" v-bind:value="a">{{a.min_age}}+</option>
                      </select>
                    </div>


                  </div>
        </div>
        </div>

        <div class="col-4">
        <div class="p-3" style="grid-template-rows: 30px 32px;background-color:#f5f5f5;border-radius:8px; border: 1px solid #F1F1F1;height: 550px;">
          <div style="font-weight: 400;font-size: 16px;line-height: 22px;text-transform: uppercase;color: #22303F;" class="mb-3"> MASAL İÇİN PROMPTLAR</div>
              <button class="btn btn-sm btn-outline-primary btn-block w-100" @click="showPromptModal(1,1)">
                Prompt Ekle
              </button>
              <div class="row d-block" style="overflow: auto;height: 450px;">
                <div class="col-12 mt-2 position-relative" v-show="row.prompt_type == 'TALE'" style="color: #222F3E;opacity: 0.8;" v-for="row in prompt" :key="row.id">
                  <div class="top-0 start-2 " style="max-width: 85%; word-wrap: break-word;text-align: justify;">{{ row.content }}</div>
                  <div class="position-absolute top-0 end-0 me-4 mt-1" @click="showPromptModal(1,row)" type="button">
                    <i class="bi bi-pencil"></i>
                  </div>
                  <div class="position-absolute top-0 end-0 me-1" @click="removePrompt(row.id)" type="button">
                    <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.7 14.3C5.88333 14.4833 6.11667 14.575 6.4 14.575C6.68333 14.575 6.91667 14.4833 7.1 14.3L10 11.4L12.925 14.325C13.1083 14.5083 13.3377 14.5957 13.613 14.587C13.8877 14.579 14.1167 14.4833 14.3 14.3C14.4833 14.1167 14.575 13.8833 14.575 13.6C14.575 13.3167 14.4833 13.0833 14.3 12.9L11.4 10L14.325 7.075C14.5083 6.89167 14.5957 6.66233 14.587 6.387C14.579 6.11233 14.4833 5.88333 14.3 5.7C14.1167 5.51667 13.8833 5.425 13.6 5.425C13.3167 5.425 13.0833 5.51667 12.9 5.7L10 8.6L7.075 5.675C6.89167 5.49167 6.66267 5.404 6.388 5.412C6.11267 5.42067 5.88333 5.51667 5.7 5.7C5.51667 5.88333 5.425 6.11667 5.425 6.4C5.425 6.68333 5.51667 6.91667 5.7 7.1L8.6 10L5.675 12.925C5.49167 13.1083 5.40433 13.3373 5.413 13.612C5.421 13.8873 5.51667 14.1167 5.7 14.3ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20ZM10 18C12.2167 18 14.1043 17.221 15.663 15.663C17.221 14.1043 18 12.2167 18 10C18 7.78333 17.221 5.89567 15.663 4.337C14.1043 2.779 12.2167 2 10 2C7.78333 2 5.896 2.779 4.338 4.337C2.77933 5.89567 2 7.78333 2 10C2 12.2167 2.77933 14.1043 4.338 15.663C5.896 17.221 7.78333 18 10 18Z" fill="#888888"/>
                    </svg>
                  </div>
                </div>
              </div>
        </div>
        </div>

        <div class="col-4">
        <div class="p-3" style="grid-template-rows: 30px 32px;background-color:#f5f5f5;border-radius:8px; border: 1px solid #F1F1F1;height: 550px;">
          <div style="font-weight: 400;font-size: 16px;line-height: 22px;text-transform: uppercase;color: #22303F;" class="mb-3"> GÖRSEL İÇİN PROMPTLAR</div>
              <button class="btn btn-sm btn-outline-primary me-2 w-100" @click="showPromptModal(2,1)">
                Prompt Ekle
              </button>
              <div class="row d-block" style="overflow: auto;height: 450px;">
                <div class="col-12 mt-2 position-relative" v-show="row.prompt_type == 'IMAGE'" style="color: #222F3E;opacity: 0.8;" v-for="row in prompt" :key="row.id">
                  <div class="top-0 start-2 " style="max-width: 85%; word-wrap: break-word;text-align: justify;">{{ row.content }}</div>
                  <div class="position-absolute top-0 end-0 me-4 mt-1" @click="showPromptModal(2,row)" type="button">
                    <i class="bi bi-pencil"></i>
                  </div>
                  <div class="position-absolute top-0 end-0 me-1" @click="removePrompt(row.id)" type="button">
                    <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.7 14.3C5.88333 14.4833 6.11667 14.575 6.4 14.575C6.68333 14.575 6.91667 14.4833 7.1 14.3L10 11.4L12.925 14.325C13.1083 14.5083 13.3377 14.5957 13.613 14.587C13.8877 14.579 14.1167 14.4833 14.3 14.3C14.4833 14.1167 14.575 13.8833 14.575 13.6C14.575 13.3167 14.4833 13.0833 14.3 12.9L11.4 10L14.325 7.075C14.5083 6.89167 14.5957 6.66233 14.587 6.387C14.579 6.11233 14.4833 5.88333 14.3 5.7C14.1167 5.51667 13.8833 5.425 13.6 5.425C13.3167 5.425 13.0833 5.51667 12.9 5.7L10 8.6L7.075 5.675C6.89167 5.49167 6.66267 5.404 6.388 5.412C6.11267 5.42067 5.88333 5.51667 5.7 5.7C5.51667 5.88333 5.425 6.11667 5.425 6.4C5.425 6.68333 5.51667 6.91667 5.7 7.1L8.6 10L5.675 12.925C5.49167 13.1083 5.40433 13.3373 5.413 13.612C5.421 13.8873 5.51667 14.1167 5.7 14.3ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20ZM10 18C12.2167 18 14.1043 17.221 15.663 15.663C17.221 14.1043 18 12.2167 18 10C18 7.78333 17.221 5.89567 15.663 4.337C14.1043 2.779 12.2167 2 10 2C7.78333 2 5.896 2.779 4.338 4.337C2.77933 5.89567 2 7.78333 2 10C2 12.2167 2.77933 14.1043 4.338 15.663C5.896 17.221 7.78333 18 10 18Z" fill="#888888"/>
                    </svg>
                  </div>
                </div>
          </div>
        </div>
        </div>

      </div>
      <div class="row mt-3">
        <div class="col-4">
        <div class="p-3 sticky-top" style="background-color:#f5f5f5;border-radius:8px; border: 1px solid #F1F1F1;min-height:50vh;">

          <div style="font-weight: 400;font-size: 16px;line-height: 22px;text-transform: uppercase;color: #22303F;" class="mb-3">
             HİKAYE</div>
            <button class="btn btn-sm btn-outline-primary me-2 w-100" @click="showTaleModal()" >
              DÜZENLE
            </button>
            <div class="mt-3 d-block text-wrap" style="overflow: auto;height: 450px;word-wrap: break-word;">
                      {{ tale.story }}
            </div>
        </div>
        </div>


        <div class="col-4" >
        <div class="p-3 sticky-top" style="background-color:#f5f5f5;border-radius:8px; border: 1px solid #F1F1F1;min-height:50vh;" >

          <div style="font-weight: 400;font-size: 16px;line-height: 22px;text-transform: uppercase;color: #22303F;" class="mb-3"> GÖRSELLER</div>
        <div class="d-grid gap-2 mt-3">
                  <input class="form-control form-control-sm btn-outline-primary me-2" type="file" @change="onFileChange" ref="file" />
        </div>



              <div class="row">
                <div class="col-12 mt-2 position-relative" style="color: #222F3E;opacity: 0.8;" v-for="row in images" :key="row.id">
                  <img :src="'https://api.aitales.appcent.dev/api/image/' + row.filename" class="img-fluid" />
                  Görsel #{{row.image_id}}
                  <div class="position-absolute top-0 end-0 me-3" @click="removeImage(row.filename)" type="button">
                    <svg width="15" height="15" viewBox="0 0 20 20" style="background-color:#fff;border-radius:10px;" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.7 14.3C5.88333 14.4833 6.11667 14.575 6.4 14.575C6.68333 14.575 6.91667 14.4833 7.1 14.3L10 11.4L12.925 14.325C13.1083 14.5083 13.3377 14.5957 13.613 14.587C13.8877 14.579 14.1167 14.4833 14.3 14.3C14.4833 14.1167 14.575 13.8833 14.575 13.6C14.575 13.3167 14.4833 13.0833 14.3 12.9L11.4 10L14.325 7.075C14.5083 6.89167 14.5957 6.66233 14.587 6.387C14.579 6.11233 14.4833 5.88333 14.3 5.7C14.1167 5.51667 13.8833 5.425 13.6 5.425C13.3167 5.425 13.0833 5.51667 12.9 5.7L10 8.6L7.075 5.675C6.89167 5.49167 6.66267 5.404 6.388 5.412C6.11267 5.42067 5.88333 5.51667 5.7 5.7C5.51667 5.88333 5.425 6.11667 5.425 6.4C5.425 6.68333 5.51667 6.91667 5.7 7.1L8.6 10L5.675 12.925C5.49167 13.1083 5.40433 13.3373 5.413 13.612C5.421 13.8873 5.51667 14.1167 5.7 14.3ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20ZM10 18C12.2167 18 14.1043 17.221 15.663 15.663C17.221 14.1043 18 12.2167 18 10C18 7.78333 17.221 5.89567 15.663 4.337C14.1043 2.779 12.2167 2 10 2C7.78333 2 5.896 2.779 4.338 4.337C2.77933 5.89567 2 7.78333 2 10C2 12.2167 2.77933 14.1043 4.338 15.663C5.896 17.221 7.78333 18 10 18Z" fill="#888888"/>
                    </svg>
                  </div>
                </div>
              </div>
        </div>
        </div>

        <div class="col-4">
        <div class="p-3 mb-2" style="background-color:#f5f5f5;border-radius:8px; border: 1px solid #F1F1F1;">

          <div style="font-weight: 400;font-size: 16px;line-height: 22px;text-transform: uppercase;color: #22303F;" class="mb-1"> ÖZET</div>
              <div class="row">
                <div class="col-12 mt-2 position-relative" style="color: #222F3E;opacity: 0.8;" >

                    <textarea required
                      type="text"
                      class="form-control"
                      id="promtInput"
                      rows="4"
                      v-model="tale.summary"
                    />
                </div>
                <div class="col-12 mt-2" style="color: #222F3E;opacity: 0.8;" >
                      <select class="form-select" aria-label="Select status" aria-placeholder="Select Status" v-model="summaryImage">
                        <option>Özet Görseli</option>
                        <option v-for="(b,index) in images" :key="index" v-bind:value="b.image_id" >Görsel #{{b.image_id}}</option>
                      </select>
              </div>
              </div>
        </div>
          <div style="font-weight: 500;font-size: 14px;line-height: 22px;text-transform: uppercase;color: #22303F;" class="mb-1"> SAYFALAR</div>

        <div class="p-3 mt-2" style="background-color:#f5f5f5;border-radius:8px; border: 1px solid #F1F1F1;" v-for="(row,index) in pages" :key="index">
          <div class="d-grid">
                <div class="col-12 mt-2" style="color: #222F3E;opacity: 0.8;" >
                  <div class="top-0 start-0 text-end" @click="deletePage(row.id,index)" type="button">
                    <svg width="15" height="15" viewBox="0 0 20 20" style="background-color:#fff;border-radius:10px;" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.7 14.3C5.88333 14.4833 6.11667 14.575 6.4 14.575C6.68333 14.575 6.91667 14.4833 7.1 14.3L10 11.4L12.925 14.325C13.1083 14.5083 13.3377 14.5957 13.613 14.587C13.8877 14.579 14.1167 14.4833 14.3 14.3C14.4833 14.1167 14.575 13.8833 14.575 13.6C14.575 13.3167 14.4833 13.0833 14.3 12.9L11.4 10L14.325 7.075C14.5083 6.89167 14.5957 6.66233 14.587 6.387C14.579 6.11233 14.4833 5.88333 14.3 5.7C14.1167 5.51667 13.8833 5.425 13.6 5.425C13.3167 5.425 13.0833 5.51667 12.9 5.7L10 8.6L7.075 5.675C6.89167 5.49167 6.66267 5.404 6.388 5.412C6.11267 5.42067 5.88333 5.51667 5.7 5.7C5.51667 5.88333 5.425 6.11667 5.425 6.4C5.425 6.68333 5.51667 6.91667 5.7 7.1L8.6 10L5.675 12.925C5.49167 13.1083 5.40433 13.3373 5.413 13.612C5.421 13.8873 5.51667 14.1167 5.7 14.3ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20ZM10 18C12.2167 18 14.1043 17.221 15.663 15.663C17.221 14.1043 18 12.2167 18 10C18 7.78333 17.221 5.89567 15.663 4.337C14.1043 2.779 12.2167 2 10 2C7.78333 2 5.896 2.779 4.338 4.337C2.77933 5.89567 2 7.78333 2 10C2 12.2167 2.77933 14.1043 4.338 15.663C5.896 17.221 7.78333 18 10 18Z" fill="#888888"/>
                    </svg>
                  </div>
                  {{row.index_number ? row.index_number : index+1}}
                    <textarea required
                      type="text"
                      class="form-control"
                      id="promtInput"
                      rows="4"
                      v-model="row.tale_snippet"
                    />
              </div>
                <div class="col-12 mt-2" style="color: #222F3E;opacity: 0.8;" >
                      <select class="form-select" aria-label="Select status" aria-placeholder="Select Status" v-model="row.image_id">
                        <option>Select image</option>
                        <option v-for="(a,index) in images" :key="index" v-bind:value="a.image_id">Görsel #{{a.image_id}}</option>
                      </select>
              </div>
              <div class="text-block-container mt-2" v-show="row.tale_snippet.length > 2 && row.image_link">
                <img :src="row.image_link" style="width:100%;">
                <div class="text-block">{{row.tale_snippet}}</div>
              </div>
          </div>
        </div>

        <div @click="addPage()" class="card mt-3 " type="button" style="background: #E9F7FE;border: 1px solid rgba(80, 167, 246, 0.12);color: #50A7F6;">
          <div class="card-body text-center fw-semibold p-1">
                <i class="bi bi-plus-circle"></i><br/>YENİ SAYFA
        </div>
        </div>

        </div>


      </div>

    </div>
    <EditModal
      v-show="isModalTale"
      @close="closeTaleModal"
      :postData="this.tale" :postID="this.$route.params.id"

    />
    <PromtModal
      v-show="isModalPromtVisible"
      @close="closePromptModal"
      :postData="this.$route.params.id"
      :promtType="this.promtType"
      :processType="this.processType"
    />
  </div>
</template>

<script>

import { get, patch, getPrompt, deletePrompt, createImage, getImages, deleteImage, getPages, deletePage, updatePage, getAges, getLanguages, createPage, getCategory, getConnect } from "../service/tales";
import EditModal from "../components/TaleModal.vue";
import PromtModal from "../components/PromtModal.vue";
import { mapState } from "vuex";
import Multiselect from 'vue-multiselect'

export default {
  name: "Facilities",
  data() {
    return {
      selectCat: [],
      tale: {},
      list: {},
      prompt: {},
      images: {},
      pages: [],
      catAll: [],
      cat_text: "",
      ages: {},
      languages: {},
      summaryImage: null,
      selectedAge: null,
      selectedLang: null,
      isModalTale: false,
      isModalPromtVisible: false,
      postData: "",
      promtType: 1,
      processType: {},
      postID: this.$route.params.id,
      interval2: null
    };
  },
  components: {
    Multiselect,
    EditModal,
    PromtModal
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    addPage() {
      this.pages.push({image_id:0,tale_snippet:"Yeni Bir Sayfa"});
    },
    onFileChange(e) {
         var files = e.target.files || e.dataTransfer.files;
          const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = (e) => {
              const image = new Image();
              image.src = e.target.result;
              image.onload = () => {
                const {
                  height,
                  width
                } = image;
                if ((width/16 != height/9) || width < 1919 /*|| files[0].size > 1048575*/) {
                  //alert("Görsel dosya boyutu maksimum 1MB, en/boy oranı 16:9 ve minimum ölçüler 1920x1080 olmalıdır.");
                  alert("Görsel en/boy oranı 16:9 ve minimum ölçüler 1920x1080 olmalıdır.");
                  this.$refs.file.value=null;
                  return false;
                }
                  createImage(this.$route.params.id,files[0]).then(()=>{
                  this.getImages();
                  this.$refs.file.value=null;
                  });
              };
            };
    },

    showPromptModal(t,p) {
      this.promtType = t;
      this.processType = p;
      this.isModalPromtVisible = true;
    },
    closePromptModal() {
      this.isModalPromtVisible = false;
      this.getPrompt();
    },
    showTaleModal() {
      this.isModalTale = true;
    },
    closeTaleModal() {
      this.isModalTale = false;
      this.loadFacilities();
    },
    loadFacilities() {
      get(this.$route.params.id).then((res) => {
        this.tale = {...res.data.data, summary:""};
                this.tale = res.data.data;
       // this.tale = {...res.data.data, summary:""};
       this.summaryImage = this.tale?.summary_image?.image_id;
       this.selectedLang = {
        name:res.data.data.language_name,
        code:res.data.data.language_code,
       }
        this.selectedAge = res.data.data.age_range;
        res.data.data.categories.map((c) => {
          this.selectCat.push({name: c.category_name, value: c.category_id})
        });
      });
      this.getPrompt();
      this.getImages();
      this.getPages();
      this.getCategory();
    },
    getCategory(){
      getCategory().then((res) => {
        res.data.data.map((c) =>  {
          this.catAll.push({name: c.name, value: c.id})
        })
      });
    },
    getImages(){
      getImages(this.$route.params.id).then((res) => {
        this.images = res.data.data;
      });
    },
    getPages() {
      getPages(this.$route.params.id).then((res) => {
        this.pages = res.data.data;
      });
    },
    deletePage(id,index) {
      if(id) {
      deletePage(id).then(() => {
        this.getPages();
      });
      } else {
        this.pages =  this.pages.filter(function(item,i) {
            return i !== index
        })
      }

    },
    removeImage(id) {
      deleteImage(id).then(() => {
      this.getImages();
    });
    },
    archive(d) {
      patch(this.$route.params.id, {is_archived:d}).then((res) => {
        this.tale = res.data.data;
      });
    },
    publish() {
      let talePromt = this.prompt.filter(function(item) {return item.prompt_type == "TALE"})
      let imagePromt = this.prompt.filter(function(item) {return item.prompt_type == "IMAGE"})


      if(
      Object.keys(talePromt).length > 0 &&
      Object.keys(imagePromt).length > 0 &&
     this.tale.categories.length > 0 &&
      this.tale.story.length > 0 &&
      this.tale.summary?.length > 0 &&
      this.tale.summary_image?.image_id) {

      var role = this.tale.role == "request" ? "complete" : "request";
      patch(this.$route.params.id, {role:role}).then((res) => {
        this.tale = res.data.data;
      });

        return;
      } else {
      this.$notify({
        group: 'foo',
        title: 'Hata',
        text: "Hikaye, Masal için İstemler, Görsel için İstemler, Özet ile Özet Görseli doldurulmuş olmalı ve en az 1 kategori seçilmelidir."
      })
      }

      },
    update() {
      let self = this;

      const cat_list = [];

    this.selectCat.map((c)=>{
      cat_list.push(c.value);
    })


      this.pages.forEach(p => {
          p.image = p.image_id;

        if(!p.id) {
        delete p.image_id;

          createPage(this.$route.params.id, p).catch(function (error) {
          if (error.response) {
            self.$notify({
              group: 'foo',
              title: 'Hata',
              text: error.response.data.msg ? error.response.data.msg : error.response.data.message
            })
          }
          });
        } else {
          updatePage(p.id,p).catch(function (error) {
          if (error.response) {
            self.$notify({
              group: 'foo',
              title: 'Hata',
              text: error.response.data.msg ? error.response.data.msg : error.response.data.message
            })
          }
          });
        }

      });


      const payload = this.tale;
      cat_list.length > 0 ? payload.categories = cat_list:'';
      payload.summary_image = this.summaryImage
      payload.summary == "" ? delete payload.summary : "";
      payload.language = this.selectedLang.code;
      delete payload.id;
      delete payload.slug;
      this.selectedAge === undefined ? delete payload.age_range : (payload.age_range = this.selectedAge.id);
      patch(this.$route.params.id, payload).then((res) => {
        this.tale = res.data.data;
          if (res.data.success) {
            self.$notify({
              group: 'foo',
              title: 'Bilgi',
              text: "Başarıyla Kaydedildi."
            })
          }

      });
    },
    close() {
        this.$router.push("/")
    },
    getPrompt() {
      getPrompt(this.$route.params.id).then((res) => {
        this.prompt = res.data.data;
      });
    },
    removePrompt(id) {
      deletePrompt(id).then(() => {
      this.getPrompt();
    });
    },
    remainingday: function (val1, val2) {
      const date1 = new Date(val1);
      const date2 = new Date(val2);
      const difference = date1.getTime() - date2.getTime();
      const days = Math.ceil(difference / (1000 * 3600 * 24));
      return days;
    },
    FicilityUsagePush: function(id){
      this.$router.push("/tale/" + id)
    },
    getConnect() {
      getConnect(this.$route.params.id).then(() =>{

      });
      if(!this.$route.params.id){
        clearInterval(this.interval2)
      }
    },
    otherDate: function() {
      getAges().then((res) => {
        this.ages = res.data.data;
      })
      getLanguages().then((res) => {
        this.languages = res.data.data;
      })
    }
  },
  mounted() {
    this.otherDate();
    this.loadFacilities();
        this.getConnect();
    this.interval2 = setInterval(function() {
        this.getConnect();
    }.bind(this), 10000);
    this.$router.afterEach(() => {
      clearInterval(this.interval2)
    });
  },
  watch: {
    facilityReload() {
      if (this.facilityReload) {
        this.$store.state.facilityReload = false;
        this.loadFacilities();
      }
    }
  },
};
</script>
