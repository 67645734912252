<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4 mb-4 ">
        <div @click="showModal()" class="card" type="button" style="background: #E9F7FE;border: 1px solid rgba(80, 167, 246, 0.12);color: #50A7F6;">
          <div class="card-body text-center fw-semibold">
                <i class="bi bi-plus-circle"></i><br/>YENİ MASAL
        </div>
      </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6 mb-6">
        <div class="card" style="background-color: rgba(0, 0, 0, 0.03);">
          <div class="card-header py-3 fw-semibold">
            <i class="bi bi-circle-fill ma-3 " style="width: 60px; color: #53ABF1; font-size:8px;"></i> <span style="font-weight: 600;font-size: 15px;line-height: 20px;color: #222F3E;"> Masal İstekleri</span>
          </div>
          <div class="card-body">
              <span v-show="!loading" v-for="row in data" :key="row.id">
                <div v-if="row.role == 'request' && !row.is_archived">
                <div class="row mb-3" @click="goTale(row)" type="button" v-bind:style="[row.metadata ? {'cursor': 'not-allowed'}:{}]">
                    <div class="border rounded pt-2  text-center col-2" style="width:60px;line-height: 30px;font-weight: 600;font-size: 15px;color: #222F3E;background-color:#fff;">{{row.id}}</div>
                    <div class=" col-10">
                      <span style="font-weight: 500;font-size: 15px;line-height: 20px;color: #222F3E;">{{row.title}}</span><br />
                      <span v-if="!row.metadata" style="font-weight: 500;font-size: 13px;color: #888888;">{{row.request_type}}</span>
                      <div v-if="row.metadata" style="font-weight: 500;font-size: 13px;color: #888888;"><div class="blink mt-1 me-2"> </div> {{row.metadata.first_name}} {{row.metadata.last_name}} düzenliyor <i class="bi bi-pencil"></i> </div>
                    </div>
                </div>
                </div>
              </span>

              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6 mb-6 ">
        <div class="card" style="background-color: rgba(0, 0, 0, 0.03);">
          <div class="card-header py-3 fw-semibold">
            <i class="bi bi-circle-fill ma-3" style="width: 60px; color: #76C39C; font-size:8px;"></i> <span style="font-weight: 600;font-size: 15px;line-height: 20px;color: #222F3E;"> Yayınlanmış</span>
          </div>
          <div class="card-body">
              <span v-show="!loading" v-for="row in data" :key="row.id">
                <div v-if="row.role == 'complete' && !row.is_archived">
                  <div class="row mb-3" @click="goTale(row)" type="button" v-bind:style="[row.metadata ? {'cursor': 'not-allowed'}:{}]">
                      <div class="border rounded pt-2 px-0 text-center col-2" style="width: 60px; line-height: 30px;font-weight: 600;font-size: 15px;color: #222F3E;background-color:#fff;">{{row.id}}</div>
                      <div class=" col-10">
                        <span style="font-weight: 500;font-size: 15px;line-height: 20px;color: #222F3E;">{{row.title}}</span><br />
                      <span v-if="!row.metadata" style="font-weight: 500;font-size: 13px;color: #888888;">{{row.request_type}}</span>
                      <div v-if="row.metadata" style="font-weight: 500;font-size: 13px;color: #888888;"><div class="blink mt-1 me-2"> </div> {{row.metadata.first_name}} {{row.metadata.last_name}} düzenliyor <i class="bi bi-pencil"></i> </div>
                      </div>
                  </div>
                </div>
              </span>

              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
          </div>
        </div>
      </div>
    </div>
  <FacilityModal
      v-show="isModalVisible"
      @close="closeModal"
      :postData="editData"
    />
  </div>
</template>

<script>
import { getAll } from "../service/tales";
import FacilityModal from "../components/FacilityModal.vue";
import Facility from "../model/facility";

export default {
  name: "Home",
  data() {
    return {
      data: {},
      editData: {},
      loading: true,
      isModalVisible: false,
      metadata: {},
      timerCount: 5,
      interval: null
    };
  },
  components: {
    FacilityModal,
  },
    methods:{
    addFacility() {
      this.editData = new Facility();
      this.isModalVisible = true;
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    getAll().then((res) => {
      this.data = res.data.data;
      this.loading = false;
    });
    },
    goTale: function(tale){
      if(!tale.metadata || tale.metadata.email == this.$store.state.user.email) {
      this.$router.push("/tale/" + tale.id);
      }
    },
    loadAll: function() {
      getAll().then((res) => {
        this.data = res.data.data;
        res.data.metadata.map((m)=> {
          this.data.find( x => x.id == m.tale_id).metadata = m;
        });
        this.loading = false;
      });
    }
  },
  mounted() {
    this.loadAll();
    this.interval = setInterval(function() {
      this.loadAll();
    }.bind(this), 10000);

  this.$router.afterEach(() => {
      clearInterval(this.interval)
    });
  }
};
</script>
